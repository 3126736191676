<template>
    <v-card class="elevation-0">

        <v-toolbar dark color="primary" class="elevation-0">
            <v-btn icon dark @click="close()">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Modificar Noticia</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-switch class="my-5 mr-10" v-model="post.is_last_minute" inset label="Último Minuto" ></v-switch>
                <v-switch class="my-5 mr-10" v-model="post.send_push" inset label="Enviar Notificación" ></v-switch>
                <v-checkbox class="my-5 mr-5" v-model="post.visibility.web" label="Página Web"></v-checkbox>
                <v-checkbox class="my-5 mr-5" v-model="post.visibility.app" label="Aplicación"></v-checkbox>
                <v-btn dark text @click="save()" :disabled="grey">
                    Publicar
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-form class="px-12 py-6">
            <v-row class="ma-0">
                <v-col cols="8" class="pb-0">
                    <v-text-field v-model="post.title" label="Título"></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0">
                    <v-autocomplete :loading="isLoadingCategories" :search-input.sync="searchCategories" hide-no-data placeholder="Escribe para buscar" attach
                        name="category"
                        label="Categorías"
                        outlined
                        v-model="post.categories" 
                        dense
                        :items="categories"
                        item-value="id"
                        class="mt-3"
                        item-text="name"
                        multiple
                        small-chips
                    ></v-autocomplete>
                </v-col>
            </v-row>
            
            <v-row class="ma-0 mb-4">
                <strong class="mr-4">Etiquetas:</strong>
                <v-chip v-for="(tag, index) in post.tags" class="mr-2" :key="index" close @click:close="remove(index)">{{tag}}</v-chip>
            </v-row>
            <v-autocomplete 
            :hide-no-data="searchTags==''||searchTags==undefined||searchTags==null"
            v-if="showAutocomplete"
            class="mt-4" 
            :items="tags" 
            :search-input.sync="searchTags" 
            @click:append="add(searchTags)" 
            v-model="new_tag"  
            outlined 
            append-icon="mdi-plus" 
            style="min-width:300px!important; max-width:300px!important; width:300px!important;" 
            dense 
            :loading="isLoadingTags"
            label="Agregar Etiqueta">
                <template v-slot:no-data="">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title>
                                No hay coincidencias
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:append-item="" v-if="searchTags!=''&&searchTags!=undefined&&searchTags!=null">
                    <v-list-item  style="cursor: pointer;">
                        <v-list-item-content @click="add(searchTags)" @keydown.enter="add(searchTags)" >
                            <v-list-item-title>
                                Agregar "{{searchTags}}"
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:item="{item, attrs, on}">
                    <v-list-item v-on="on" v-bind="attrs">
                        <v-list-item-content @keydown.enter="add(item)">
                            <v-list-item-title>
                                {{item}}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-autocomplete>
            <v-row class="ma-0">
                <strong>Contenido:</strong>
                <v-spacer/>
                <v-dialog v-model="dialog" width="600px">
                    <template v-slot:activator="{ props }">
                        <v-btn @click="dialog = true" v-bind="props" small class="elevation-0 mr-4" style="transform:translateY(37px);">Subir Video</v-btn>
                    </template>
                    <v-card class="pa-6">
                        <vue-dropzone 
                        v-bind:auth="dropzoneOptions2.headers"
                        ref="myVueDropzone2" 
                        id="dropzone2" 
                        :options="dropzoneOptions2" 
                        v-on:vdropzone-success="uploadSuccess2" 
                        v-on:vdropzone-error="uploadError2" 
                        v-on:vdropzone-removed-file="fileRemoved2"/>

                        <v-row v-if="video_url!=''" class="ma-0 pt-4">
                            <v-spacer/>
                            <div style="text-align:center;">
                                <strong>Copia el link que se genera a continuación </strong>
                                <br/>
                                <v-btn small class="elevation-0 my-2" @click="copyUrl">{{video_url}}</v-btn>
                                <br/>
                                <strong>y pegalo en la opcion de insertar video en el cuerpo de la noticia</strong>
                            </div>
                            <v-spacer/>
                        </v-row>
                        <v-snackbar :color="snackbar2.color" v-model="snackbar2.show">
                            <strong>{{ snackbar2.message }}</strong>
                        </v-snackbar>
                    </v-card>
                </v-dialog>
            </v-row>
            <vue-editor
                use-custom-image-handler
                @image-added="handleImageAdded"
                v-model="post.content"
                :editorOptions="editorSettings"
                :editor-toolbar="customToolbar"
                >
            </vue-editor>

            <v-row class="mx-0 mt-4">
                <strong class="mr-4" style="line-height:60px;">Por:</strong>
                <v-text-field v-model="post.written_by" outlined></v-text-field>
            </v-row>
            
            <v-row class="mb-4 mx-1">
                <v-textarea v-model="post.short_description"
                counter
                maxlength="100"
                outlined  
                label="Descripción Corta"></v-textarea>
                <v-spacer/>

                <div>

                    <vue-dropzone 
                    v-bind:auth="dropzoneOptions.headers"
                    ref="myVueDropzone" 
                    id="dropzone" 
                    :options="dropzoneOptions" 
                    v-on:vdropzone-success="uploadSuccess" 
                    v-on:vdropzone-error="uploadError" 
                    v-on:vdropzone-removed-file="fileRemoved"/>
                    
                    
                    <v-file-input :loading="loadingGallery" outlined class="mt-6" dense label="Agregar imagenes de galería" v-model="imageData" multiple ref="image" accept="image/*"></v-file-input>

                    {{gallery_data}}

                    <draggable v-if="post.gallery_data" :list="post.gallery_data" class="list-group mb-4" draggable=".item" group="a">
                        <v-col style="display:inline-block;" cols="2" class="list-group-item item ma-1 bgsize" v-for="(photo, index) in post.gallery_data" :key="index" :style="'background:url(' + photo + ');'">
                            <v-row class="ma-0">
                                <v-spacer/>
                                    <v-btn style="background: rgb(0 0 0 / 69%);" icon class="close" @click="removePhoto(index)" dark><v-icon>mdi-close</v-icon></v-btn>
                                <v-spacer/>
                            </v-row>
                        </v-col>
                    </draggable>


                </div>
            </v-row>
        </v-form>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            <strong>{{ snackbar.message }}</strong>
        </v-snackbar>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable'

import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { VueEditor,Quill } from 'vue2-editor';

import ImageResize from 'quill-image-resize-vue';
import { ImageDrop } from 'quill-image-drop-module';

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);

import axios from 'axios'
export default {
    components: { 
        VueEditor,
        vueDropzone: vue2Dropzone,
        draggable
    },
    mounted(){
        var file = { size: 1230, name: "Imagen Destacada", type: "image/png" };
        var url = this.post.featured_media_path;
        this.$refs.myVueDropzone.manuallyAddFile(file, url);
    },
    watch:{
        post:{
            handler(){
                var file = { size: 1230, name: "Imagen Destacada", type: "image/png" };
                var url = this.post.featured_media_path;
                this.$refs.myVueDropzone.manuallyAddFile(file, url);
            }
        },
        new_tag:{
            handler(){
                if(this.new_tag!=''){
                    this.add(this.new_tag)
                }
            },deep:true
        },
        imageData:{
            handler(){   
                if(this.imageData!=''){
                    this.loadingGallery = true  
                    let formData = new FormData();
                    for (var i = 0; i < this.imageData.length; i++ ){
                        let file = this.imageData[i];
                        formData.append('files[' + i + ']', file);
                    }
                    axios.post(process.env.VUE_APP_BACKEND_ROUTE + "api/v1/post/image-gallery"
                        ,formData
                        ,{headers: {"Content-Type": "multipart/form-data"}}
                    ).then(response=>{
                        console.log(response.data)
                        this.post.gallery_data = response.data//.map(file=>process.env.VUE_APP_BACKEND_ROUTE + 'files/' + file)
                        console.log(this.post.gallery_data)
                        this.loadingGallery = false
                    })
                }
            },deep:true,
        },
        searchTags(val){
            if(val!=null&&val!=''&&val!=undefined){
                //if (this.companyLists.length > 0) return
                if (this.isLoadingTags) return
                this.isLoadingTags = true
                axios.get(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/tags?filter[name]='+val)
                .then(res => {
                    this.tags = res.data.data.map(tag=>tag.name)
                }).finally(() => (this.isLoadingTags = false))
            }
        },
    },
    props:{
        post:Object
    },
    data: () => ({video_url:'',
        dialog:false,
        customToolbar: [
            [{ 'font': [] }],
            [{ 'header': [false, 1, 2, 3, 4, 5, 6, ] }],
            [{ 'size': ['small', false, 'large', 'huge'] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{'align': ''}, {'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
            //[{ 'header': 1 }, { 'header': 2 }],
            ['blockquote', 'code-block'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }],
            ['link', 'image', 'video', 'formula'],
            [{ 'direction': 'rtl' }],
            ['clean'],
        ],
        loadingGallery:false,
        showAutocomplete:true,
        isLoadingTags:false,
        imageData:'',
        searchCategories:'',
        isLoadingCategories:false,
        searchTags:'',
        new_tag:'',
        tags:[],
        editorSettings: {
            modules: {
                imageDrop: true,
                imageResize: {},
            }
        },
        image:'',
        snackbar: {
            show: false,
            message: null,
            color: null
        },
        snackbar2: {
            show: false,
            message: null,
            color: null
        },
        dropzoneOptions: {
            url: process.env.VUE_APP_BACKEND_ROUTE + "api/v1/post/featured_image",
            addRemoveLinks: true,
            maxFiles: 1,
            thumbnailWidth: 400,
            dictDefaultMessage: 'Haz clic aquí para editar o arrastra la imagen destacada. <br/><strong>Se recomienda relación aspecto 16:9</strong><br/><span style="font-size:14px; color:#b3b3b3;">Medida sugerida: 960 x 540 pixeles<span/>',
            dictFallbackMessage: "Tu navegador no puede subir archivos arrastarndolos a la pantalla.",
            dictFileTooBig: "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
            dictInvalidFileType: "No puede cargar archivos de este tipo.",
            dictCancelUpload: "Cancelar carga",
            dictCancelUploadConfirmation: "Estás seguro de que deseas cancelar esta carga?",
            dictRemoveFile: "Eliminar",
            dictMaxFilesExceeded: "No puedes subir más archivos.",
            headers:{"Authorization":'Bearer ' + localStorage.getItem("token")},
        },
        dropzoneOptions2: {
            url: process.env.VUE_APP_BACKEND_ROUTE + "api/v1/post/video",
            addRemoveLinks: true,
            maxFiles: 1,
            //thumbnailWidth: 150,
            dictDefaultMessage: 'Haz clic aquí o arrastra el video a cargar. <br/><strong>Posterior a esto copia el link que se genera a continuación</strong><br/><span style="font-size:14px; color:#b3b3b3;">Y pegalo en la opcion de insertar video en el cuerpo de la noticia<span/>',
            dictFallbackMessage: "Tu navegador no puede subir archivos arrastarndolos a la pantalla.",
            dictFileTooBig: "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB.",
            dictInvalidFileType: "No puede cargar archivos de este tipo.",
            dictCancelUpload: "Cancelar carga",
            dictCancelUploadConfirmation: "Estás seguro de que deseas cancelar esta carga?",
            dictRemoveFile: "Eliminar",
            dictMaxFilesExceeded: "No puedes subir más archivos.",
            headers:{"Authorization":'Bearer ' + localStorage.getItem("token")},
        },
    }),
    computed:{
        currentUser:{
            get(){
              return this.$store.state.currentUser.user
            }
          },
        categories(){
            return this.$store.state.category.categories
        },
        grey(){
            if((this.post.categories.length==0||
            this.post.content==''||this.post.content==null||this.post.content==undefined||
            this.post.title==''||this.post.title==null||this.post.title==undefined||
            this.post.featured_media_path==''||this.post.featured_media_path==null||this.post.featured_media_path==undefined||
            this.post.short_description==''||this.post.short_description==null||this.post.short_description==undefined) 
            || (!this.post.visibility.app && !this.post.visibility.web) ){
                return true
            }else{
                return false
            }
        }
    },
    methods:{
         copyUrl(){
            navigator.clipboard.writeText(this.video_url)
            this.snackbar2 = {
                message: 'Enlace copiado con éxito',
                color: 'success',
                show: true
            }
        },
        removePhoto(index){
            this.post.gallery_data.splice(index, 1);
        },
        add(item) {
            if(item!=''&&item!=undefined&&item!=null){
                this.post.tags.push(item);
                this.searchTags = ''
                this.new_tag = ''
                this.tags = []
                this.showAutocomplete = false
                this.$nextTick(() => {
                    this.showAutocomplete = true
                })
            }
        },
        remove(index) {
            this.post.tags.splice(index, 1);
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            var formData = new FormData();
            formData.append("image", file);
            axios({
                url: process.env.VUE_APP_BACKEND_ROUTE + "api/v1/post/image",
                method: "POST",
                data: formData,
            })
            .then(response => {
                const url = response.data.url
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
            })
            .catch(err => {
                this.snackbar = {
                    message: err,
                    color: 'error',
                    show: true
                }
            });
        },
        uploadSuccess(file, response) {
            this.image = file
            const image = response
            this.post.featured_media_path = image.featured_image
            this.post.socialshare_image_path = image.social_image
            this.colorFile = 'success'
        },
        uploadError(file, message) {
            this.snackbar = {
                message: 'No se pudo cargar la imagen',
                color: 'error',
                show: true
            }
            this.colorFile = 'error'
        },
        fileRemoved() {
            this.image = ''
        },
        uploadSuccess2(file, response) {
            this.video_url = process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/video/' + response.file
        },
        uploadError2(file, message) {
            this.snackbar = {
                message: 'No se pudo cargar la imagen',
                color: 'error',
                show: true
            }
        },
        fileRemoved2() {
            this.video_url = ''
        },
        save(){
            axios.patch(process.env.VUE_APP_BACKEND_ROUTE + 'api/v1/posts/' + this.post.id, this.post).then(response=>{
                this.close()
            })
        },
        close(){
            this.imageData = ''
            this.$refs.myVueDropzone.removeAllFiles()
            if(this.image!=''&&this.image!=null&&this.image!=undefined){
                this.$refs.myVueDropzone.removeFile(this.image)
            }
            this.$nextTick(() => {
                this.$emit("closeEditDialog", false);
            })
        }
    },
}
</script>
<style>
.bgsize{
    background-size:cover!important;
}
</style>